@tailwind base;
@tailwind components;
@tailwind utilities;

/* PACKAGE STYLES */
@import "react-phone-number-input/style.css";
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/autoplay";
@import "swiper/css/autoplay";
@import "swiper/css/effect-fade";
@import "react-toastify/dist/ReactToastify.css";

/* FONTS */
@font-face {
  font-family: "Circular-Bold";
  src: url("../assets/fonts/circular-pro-bold.ttf");
}

@font-face {
  font-family: "Circular-Medium";
  src: url("../assets/fonts/circular-pro-medium.ttf");
}

@font-face {
  font-family: "Circular-Regular";
  src: url("../assets/fonts/circular-pro-book.ttf");
}

/* FONTS */
@font-face {
  font-family: "ProximaNova-Thin";
  src: url("../assets/fonts/ProximaNova-Thin.woff");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url("../assets/fonts/ProximaNova-Light.woff");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("../assets/fonts/ProximaNova-Regular.woff");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("../assets/fonts/ProximaNova-Semibold.woff");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../assets/fonts/ProximaNova-Bold.woff");
}

@font-face {
  font-family: "ProximaNova-Extrabold";
  src: url("../assets/fonts/ProximaNova-Extrabld.woff");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("../assets/fonts/InterTight-Bold.ttf");
}

@font-face {
  font-family: "Inter-Semibold";
  src: url("../assets/fonts/InterTight-SemiBold.ttf");
}

@font-face {
  font-family: "Tenon-Extrabold";
  src: url("../assets/fonts/tenon-xbold-otf.otf");
}

@font-face {
  font-family: "Tenon-Bold";
  src: url("../assets/fonts/tenon-bold-2.otf");
}

html {
  scroll-behavior: smooth;
}

.container {
  max-width: 150vh !important;
}

/* PHONE INPUT STYLES */
.PhoneInputInput {
  font-family: "ProximaNova-Semibold", sans-serif;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 1vh 1.7vh;
  font-size: 1.5vh;
}

.error-phone .PhoneInputInput {
  border: 1px solid rgba(229, 115, 115, 1);
}

.PhoneInputInput::placeholder {
  font-family: "ProximaNova-Regular", sans-serif;
}

.PhoneInputCountry {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 1vh;
}

.error-phone .PhoneInputCountry {
  border: 1px solid rgba(229, 115, 115, 1);
}

/* SWIPER ARROWS */
.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.main-header {
  box-shadow: 0px 3px 5px rgba(57, 63, 72, 0.2);
}

.bg-banner {
  height: 92vh;
  background-image: url("../assets/images/green-banner.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

body::-webkit-scrollbar {
  display: none;
}

.hollow-text {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #25c19b;
  -webkit-text-stroke-width: 1px;
  transition: 0.5s ease-in-out;
}

.hollow-text:hover {
  -webkit-text-fill-color: #25c19b;
}

@media (max-width: 575px) {
  .container {
    padding: 0vh 1.5vh;
  }

  .bg-banner {
    height: auto !important;
  }
}
